import React from "react";
import Layout from "@components/layout/layout";
import image from "@images/logos/mbb-logo-1.png";
import "@styles/styles.scss";

export default () => {
  return (
    <Layout pageTitle="Babysitting">
      <div style={{ height: "15vh" }}></div>
      <div className="container">
        <div className="columns">
          <div className="column"></div>
          <div className="column is-12">
            <div className="box">
              <div className="container">
                <div className="title has-text-centered">Need An Occasional Sitter?</div>
                <div className="section">
                  <div className="columns is-multiline">
                    <div className="container has-text-centered">
                      Check out our original service My Boulder Babysitter! We have been growing our team of fully pre-vetted experienced babysitters since 2012! Book instantly online, or post to our job board!
                    </div>
                    <br />
                    <div className="column is-half is-offset-one-quarter">
                      <img src={image} alt="" />
                    </div>
                    <div className="column is-half is-offset-one-quarter has-text-centered">
                      <a href="https://myboulderbabysitter.com">
                        <button
                          className="button is-link"
                          href="https://www.myboulderbabysitter.com"
                        >
                          My Boulder Babysitter
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </Layout>
  );
};
